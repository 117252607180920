export default {
  "version": "1.0",
  "api": {
    "googleDriveUrlResolve": "https://old.api.sopotniki.org/volunteer/file/",
    "productionOld": "https://old.api.sopotniki.org/admin/",
    "productionNew": "https://sopotniki-v2.azurewebsites.net/admin/",
    "stageOld": "https://sopotniki-staging.azurewebsites.net/admin/",
    "stageNew": "https://sopotniki-v2-staging.azurewebsites.net/admin/",
    "sopotnikiVPS": "https://api.sopotniki.org/api/",
  }
}
