export const ENGLISH_TRANSLATIONS = {
    text: {



        globals: {
            noOptions: "Ni zadetkov.",
            noData: 'Ni podatkov.',
            back: "Nazaj",
            cancel: "Prekliči",
            close: "Zapri",
            error: "Napaka",
            accept: "Potrdi",
            continue: "Nadaljuj",
            save: "Shrani",
            delete: "Izbriši",
            edit: "Uredi",
            datePickerLocale: "en",
            search: "Išči",
            yes: "DA",
            no: "NE",
            ok: "OK",
            download: "Prenesi",
            print: "Tiskaj",
            enterValue: "vnesi vrednost",
            selectDate: "izberite datum",
            upload: "Naloži",
            searchQuery: "Iskalni niz",
            description: "Opis",
            warningTitle: "Opozorilo",
            documentPreviewNotAvailable: "Predogled gradiva ni na voljo!",
            court: "Sodišče",
            actions: "Akcije",
            adminTools: "Admin orodja",
            documentInformation: "Podatki o dokumentu",
            documentMetadata: "Metapodatki dokumenta",
            documentPreview: "Predogled dokumenta",
            saveChanges: "Shrani spremembe",
            discardChanges: "Opusti spremembe",
            status: "Status",
            caseNumber: "Opravilna številka zadeve",
            classificationPlanNotDefined: " Klasifikacijski načrt ni opredeljen.",
            comment: "Komentar",
            commentMissing: "Komentar je obvezen in mora biti dolg vsaj 8 znakov.",
            date: "Datum",
            rootFolder: "Korenska mapa",
            selectedClass: "Izbrani razred",

            export: "Izvoz",
            move: "Premesti",

            minorMajorOptions: ['MINOR', 'MAJOR'],

            confirm: "Potrdi",
            reject: "Zavrni",

            and: "IN",
            or: "ALI",
            not: "NE",

            customDatePickerShortcuts: [
                {key: 'thisWeek', label: 'Ta teden', value: 'isoWeek'},
                {key: 'lastWeek', label: 'Prejšnji teden', value: '-isoWeek'},
                {key: 'last7Days', label: 'Zadnjih 7 dni', value: 7},
                {key: 'last30Days', label: 'Zadnjih 30 dni', value: 30},
                {key: 'thisMonth', label: 'Ta mesec', value: 'month'},
                {key: 'lastMonth', label: 'Prejšnji mesec', value: '-month'},
                {key: 'thisYear', label: 'Letošnje leto', value: 'year'},
                {key: 'lastYear', label: 'Prejšnje leto', value: '-year'}
            ],
            datePick: {
                nextMonthCaption: 'Naprej',
                prevMonthCaption: 'Nazaj',
                setTimeCaption: 'Določi čas:',
                weekdays: [
                    'Pon', 'Tor', 'Sre', 'Čet', 'Pet', 'Sob', 'Ned'
                ]
                ,
                months: [
                    'januar', 'februar', 'marec', 'april',
                    'maj', 'junij', 'julij', 'avgust',
                    'september', 'oktober', 'november', 'december'
                ]

            }
        }
    }
}
