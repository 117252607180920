/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Sopotniki Admin 2.0.
  Author: Zavod Sopotniki (Markus Zevnik)
  Author URL: https://www.sopotniki.org
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vue Router
import router from './router'

import _config from './helpers/api_config';
Vue.prototype.$config = _config;

import globalFunctions from "./helpers/globalFunctions";
Vue.prototype.$globalFunctions = globalFunctions;

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)


// axios
import axios from './axios.js'
Vue.prototype.$http = axios

axios.interceptors.request.use(
  config => {
    let alfrescoToken = null;
    const token = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).token : null;

    console.log("config --->", config);

    if (config.url.includes('alfresco') && !config.url.includes('tickets')) {
      if(alfrescoToken === null) {
        alfrescoToken = sessionStorage.getItem("alfrescoUserInfo") ? JSON.parse(sessionStorage.getItem("alfrescoUserInfo")).ticket : null;
      }
      config.headers.common["Authorization"] = "Basic " + btoa(alfrescoToken);

    } else {
      if (token) {
        if (config.url.includes('https://api.sopotniki.org/')) {
          config.headers.common["Authorization"] = 'Bearer ' + token;
        } else {
          config.headers.common["Authorization"] = token;
        }

        config.headers.common["unit_id"] = Vue.prototype.$globalFunctions.getCurrentUnit().id;
      }
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {

  if (error.response && (error.response.status === 401 || error.response.status === 405)) {
    console.error("Session not valid!");
    router.push({name: 'Login'}).catch(() => {});
    // Vue.$confirm({
    //   title: 'Napaka ' + error.response.status + '!',
    //   message: 'Sistem je zaznal, da nimate ustreznih pravic za izvedbo želene akcije oz. vam je potekla seja in je potrebna ponovna prijava v sistem. Po kliku boste preusmerjeni na prijavno stran.',
    //   button: {
    //     yes: 'OK'
    //   },
    //   callback: confirm => {
    //     router.push({name: 'Login'}).catch(() => {});
    //     console.log("confirm", confirm);
    //   }
    // });

  } else if(error.response.status >= 500) {
    Vue.$confirm({
      title: 'Napaka ' + error.response.status + '!',
      message: 'Prišlo je do napake na strežniku. Prosim, obvestite administratorje.',
      button: {
        yes: 'OK'
      },
      callback: confirm => {
        console.log("confirm", confirm);
      }
    });
  }

  // Do something with response error
  return Promise.reject(error);
});

// Filters
import './filters/filters.js'


// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'





// Vuex Store
import store from './store/store'


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

import VueConfirmDialog from 'vue-confirm-dialog'
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')


// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

import _ from 'lodash';

import Moment from 'moment';
Moment.locale("SL");
Vue.prototype.moment = Moment;

import VueI18n from 'vue-i18n';
import { ENGLISH_TRANSLATIONS } from './translations/en';
import { SLOVENE_TRANSLATIONS } from './translations/sl';
Vue.use(VueI18n);
const TRANSLATIONS = {
  en: ENGLISH_TRANSLATIONS,
  sl: SLOVENE_TRANSLATIONS
};
const i18n = new VueI18n({
  locale: 'en',
  messages: TRANSLATIONS,
});
Vue.prototype.i18n = i18n;

import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
Vue.use(VueFileAgent);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAKHU6Yja1KxZKmRZQStOWSzVTq0j8heW8',
    libraries: 'places,drawing,geometry', // This is required if you use the Autocomplete plugin
    region: 'SI',
    language: 'sl',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
});

import VueGoogleCharts from 'vue-google-charts'
Vue.use(VueGoogleCharts);

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

Vue.config.productionTip = false;


Vue.filter('prettyBytes', function (num) {
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError('Expected a number');
  }

  let exponent;
  let unit;
  let neg = num < 0;
  let units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B';
  }

  exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? '-' : '') + num + ' ' + unit;
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



