/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Sopotniki Admin 2.0.
  Author: Zavod Sopotniki (Markus Zevnik)
  Author URL: https://www.sopotniki.org
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import store from '../src/store/store.js';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'Home',
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/pages/ReservationIndex.vue"),
          redirect: "/rezervacije/koledar",
          children: [
            // dashboard
            {
              name: "Danes",
              path: "/rezervacije/danes",
              component: () => import("./views/pages/Today"),
              meta: {
                parent: 'rezervacije',
                requiresAuth: true,
              }
            },
            {
              name: "Koledar rezervacij",
              path: "/rezervacije/koledar",
              component: () => import("./views/Home.vue"),
              meta: {
                parent: 'rezervacije',
                requiresAuth: true,
              }
            },
            {
              name: "Seznam rezervacij",
              path: "/rezervacije/seznam",
              component: () => import("./views/pages/Reservations"),
              meta: {
                parent: 'rezervacije',
                requiresAuth: true,
              }
            },
            {
              path: '/rezervacije/rezervacija/:reservationId?',
              name: 'Rezervacija',
              component: () => import('./views/pages/Reservation'),
              meta: {
                parent: 'seznamRezervacij',
                requiresAuth: true,
              }
            },
            {
              name: "CanceledReservations",
              path: "/rezervacije/odpovedani-prevozi",
              component: () => import("./views/pages/CanceledReservations"),
              meta: {
                parent: 'rezervacije',
                requiresAuth: true,
              }
            },
            {
              name: "DeclinedReservations",
              path: "/rezervacije/zavrnjeni-prevozi",
              component: () => import("./views/pages/DeclinedReservations"),
              meta: {
                parent: 'rezervacije',
                requiresAuth: true,
              }
            },
            {
              name: "DeclinedReservationEdit",
              path: "/rezervacije/zavrnjeni-prevozi/:reservationId?",
              component: () => import("./views/pages/CanceledOrDeclinedReservationEdit"),
              meta: {
                parent: 'zavrnjeniPrevozi',
                requiresAuth: true,
              }
            },
            {
              name: "CanceledReservationEdit",
              path: "/rezervacije/odpovedani-prevozi/:reservationId?",
              component: () => import("./views/pages/CanceledOrDeclinedReservationEdit"),
              meta: {
                parent: 'odpovedaniPrevozi',
                requiresAuth: true,
              }
            },
            {
              name: "IntermodalReservations",
              path: "/rezervacije/intermodalni-prevozi",
              component: () => import("./views/pages/IntermodalReservations"),
              meta: {
                parent: 'rezervacije',
                requiresAuth: true,
              }
            },
            {
              name: "IntermodalReservationEdit",
              path: "/rezervacije/intermodalen-prevoz/:reservationId?",
              component: () => import("./views/pages/IntermodalReservationEdit"),
              meta: {
                parent: 'intermodalniPrevozi',
                requiresAuth: true,
              }
            },
          ]
        },
        {
          path: '/dnevna-porocila',
          name: 'Dnevna poročila',
          component: () => import('./views/pages/DailyReportsIndex'),
          redirect: "/dnevna-porocila/seznam",
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              name: "Dnevna poročila",
              path: '/dnevna-porocila/seznam',
              component: () => import('./views/pages/DailyReports'),
              meta: {
                parent: 'dnevna-porocila',
                requiresAuth: true,
              }
            },
            {
              name: "Dnevno poročilo",
              path: '/dnevna-porocila/dnevno-porocilo/:dailyReportId?',
              component: () => import('./views/pages/DailyReport'),
              meta: {
                parent: 'dnevna-porocila',
                requiresAuth: true,
              }
            },
          ]
        },
        {
          path: '/mesecna-porocila',
          name: 'Mesečna poročila',
          component: () => import('./views/pages/MonthlyReportIndex'),
          redirect: "/mesecna-porocila/seznam",
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              name: "MonthlyReports",
              path: '/mesecna-porocila/seznam',
              component: () => import('./views/pages/MonthlyReports'),
              meta: {
                parent: 'mesecna-porocila',
                requiresAuth: true,
              }
            },
            {
              name: "MonthlyReportEdit",
              path: '/mesecna-porocila/mesecno-porocilo/:monthlyReportId?',
              component: () => import('./views/pages/MonthlyReport'),
              meta: {
                parent: 'mesecna-porocila',
                requiresAuth: true,
              }
            },
          ]
        },
        {
          path: '/prostovoljci',
          name: 'Prostovoljci',
          component: () => import('./views/pages/Volunteers'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/prostovoljci/:volunteerId?',
          name: 'Prostovoljec',
          component: () => import('./views/pages/Volunteer'),
          meta: {
            parent: 'Prostovoljci',
            requiresAuth: true,
          }
        },
        {
          path: '/uporabniki',
          name: 'Uporabniki',
          component: () => import('./views/pages/Customers'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/uporabniki/:customerId?',
          name: 'Uporabnik',
          component: () => import('./views/pages/Customer'),
          meta: {
            parent: 'Uporabniki',
            requiresAuth: true,
          }
        },
        {
          path: '/uporabniki/mobilna-aplikacija/:customerId?',
          name: 'CustomerAsMobileAppUserRegistration',
          component: () => import('./views/pages/CustomerAsMobileAppUserRegistration'),
          meta: {
            parent: 'Uporabniki',
            requiresAuth: true,
          }
        },

        {
          path: '/gotovina',
          component: () => import('./views/pages/FinancialReportIndex'),
          redirect: "/gotovina/seznam",
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: '/gotovina/seznam',
              name: 'FinancialReports',
              component: () => import('./views/pages/FinancialReports'),
              meta: {
                parent: 'gotovina',
                requiresAuth: true,
              }
            },
            {
              path: '/gotovina/vnos/:financialReportId?',
              name: 'FinancialReportEdit',
              component: () => import('./views/pages/FinancialReportEdit'),
              meta: {
                parent: 'gotovina',
                requiresAuth: true,
              }
            },
          ]
        },

        {
          path: '/donacije/zasebniki',
          component: () => import('./views/pages/PrivateDonationsIndex'),
          redirect: "/donacije/zasebniki/seznam",
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: '/donacije/zasebniki/seznam',
              name: 'PrivateDonations',
              component: () => import('./views/pages/PrivateDonations'),
              meta: {
                parent: 'donacijeZasebnikov',
                requiresAuth: true,
              }
            },
            {
              path: '/donacije/zasebniki/donacija/:donationId?',
              name: 'PrivateDonationEdit',
              component: () => import('./views/pages/PrivateDonationEdit'),
              meta: {
                parent: 'donacijeZasebnikov',
                requiresAuth: true,
              }
            },
          ]
        },
        {
          path: '/donacije/podjetja',
          name: 'Podjetja',
          component: () => import('./views/pages/CompanyDonations'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/donacije/podjetja/:companyId?',
          name: 'Podjetje',
          component: () => import('./views/pages/CompanyDonationEdit'),
          meta: {
            parent: 'donacijePodjetij',
            requiresAuth: true,
          }
        },
        {
          path: '/donacije/podjetja/:companyId/kampanije',
          name: 'Kampanije',
          component: () => import('./views/pages/CompanyCampaigns'),
          meta: {
            parent: 'donacijePodjetij',
            requiresAuth: true,
          }
        },
        {
          path: '/donacije/podjetja/:companyId/kampanija/:campaignId?',
          name: 'Kampanija',
          component: () => import('./views/pages/CompanyCampaignEdit'),
          meta: {
            parent: 'donacijePodjetij',
            requiresAuth: true,
          }
        },
        {
          path: '/donacije/donacije-za-enote',
          component: () => import('./views/pages/DonationsForUnitIndex'),
          redirect: "/donacije/donacije-za-enote/seznam",
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: '/donacije/donacije-za-enote/seznam',
              name: 'DonacijeZaEnote',
              component: () => import('./views/pages/DonationsForUnit'),
              meta: {
                parent: 'DonacijeZaEnote',
                requiresAuth: true,
              }
            },
            {
              path: '/donacije/donacije-za-enote/donacija/:lunchOptionId?',
              name: 'Kosilo',
              component: () => import('./views/pages/DonationForUnitEdit'),
              meta: {
                parent: 'DonacijeZaEnote',
                requiresAuth: true,
              }
            },
          ]
        },
        {
          path: '/evalvacija-dogodkov',
          name: 'EvalvacijaDogodkov',
          component: () => import('./views/pages/EventsEvaluation.vue'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/evalvacija-dogodkov/:eventId?',
          name: 'Dogodek',
          component: () => import('./views/pages/EventEdit.vue'),
          meta: {
            parent: 'EvalvacijaDogodkov',
            requiresAuth: true,
          }
        },
        {
          path: '/imenik-zdravstvenih-ustanov',
          component: () => import('./views/pages/PhoneBookIndex'),
          redirect: "/imenik-zdravstvenih-ustanov/imenik",
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: '/imenik-zdravstvenih-ustanov/imenik',
              name: 'PhoneBook',
              component: () => import('./views/pages/PhoneBook'),
              meta: {
                parent: 'imenik',
                requiresAuth: true,
              }
            },
            {
              path: '/imenik-zdravstvenih-ustanov/vnos/:phoneBookId?',
              name: 'PhoneBookEdit',
              component: () => import('./views/pages/PhoneBookEdit'),
              meta: {
                parent: 'imenik',
                requiresAuth: true,
              }
            },
          ]
        },
        {
          path: '/enote',
          name: 'Enote',
          component: () => import('./views/pages/Units.vue'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/obvestila-za-uporabnike',
          component: () => import('./views/pages/NotificationsIndex'),
          redirect: "/obvestila-za-uporabnike/obvestila",
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: '/obvestila-za-uporabnike/obvestila',
              name: 'Notifications',
              component: () => import('./views/pages/Notifications'),
              meta: {
                parent: 'obvestila',
                requiresAuth: true,
              }
            },
            {
              path: '/obvestila-za-uporabnike/obvestilo/:notificationId?',
              name: 'NotificationEdit',
              component: () => import('./views/pages/NotificationEdit'),
              meta: {
                parent: 'obvestila',
                requiresAuth: true,
              }
            },
          ]
        },
        {
          path: '/sluzbeni-potni-nalogi',
          component: () => import('./views/pages/TravelOrdersIndex'),
          redirect: "/sluzbeni-potni-nalogi/seznam",
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              name: "Sluzbeni potni nalogi",
              path: '/sluzbeni-potni-nalogi/seznam',
              component: () => import('./views/pages/TravelOrders'),
              meta: {
                parent: 'sluzbeniPotniNalogi',
                requiresAuth: true,
              }
            },
            {
              name: "Sluzbeni potni nalog",
              path: '/sluzbeni-potni-nalogi/sluzbeni-potni-nalog/:travelOrderId?',
              component: () => import('./views/pages/TravelOrder'),
              meta: {
                parent: 'sluzbeniPotniNalogi',
                requiresAuth: true,
              }
            },
          ]
        },
        {
          path: '/obrazci',
          name: 'Obrazci',
          component: () => import('./views/pages/Forms'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/dokumentni-sistem',
          name: 'DokumentniSistem',
          component: () => import('./views/pages/DocumentSystem.vue'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/upravljanje-soglasij',
          name: 'UpravljanjeSoglasij',
          component: () => import('./views/pages/ConsentsManagement.vue'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/upravljanje-soglasij/:consentId?',
          name: 'Soglasje',
          component: () => import('./views/pages/ConsentsManagementEdit.vue'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/statistika',
          name: 'Statistika',
          component: () => import('./views/pages/Statistics'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/optia-1000',
          name: 'OPTIA1000',
          component: () => import('./views/pages/StatistialReports'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/optia-1000/porocilo/:statisticalReportId?',
          name: 'StatisticalReportEdit',
          component: () => import('./views/pages/StatisticalReportEdit'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/admin-tools',
          name: 'AdminTools',
          component: () => import('./views/pages/AdminTools'),
          meta: {
            requiresAuth: true,
          }
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/pages/login',
          name: 'Login',
          component: () => import('@/views/pages/Login.vue')
        },
        {
          path: '/pages/error-404',
          name: '404',
          component: () => import('@/views/pages/Error404.vue')
        },
      ]
    },
    // =============================================================================
    // GENERATE PDF LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/pdf/Pdf.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/pdf-statistical-report/:statisticalReportId',
          name: 'PdfStatisticalReport',
          component: () => import('@/components/Sopotniki/statisticalReports/StatisticalReport')
        },
        {
          path: '/volunteer/id-card/:volunteerId',
          name: 'VolunteerIdCard',
          component: () => import('@/components/Sopotniki/volunteers/IdCard')
        },
        {
          path: '/volunteer/dogovor-o-prostovoljskem-delu/:volunteerId',
          name: 'DogovorOprostovoljskemDelu',
          component: () => import('@/components/Sopotniki/volunteers/DogovorOprostovoljskemDelu')
        },
        {
          path: '/volunteer/izjava-prostovoljca/:volunteerId',
          name: 'IzjavaProstovoljca',
          component: () => import('@/components/Sopotniki/volunteers/IzjavaProstovoljca')
        },
        {
          path: '/volunteer/covid-testiranje-prostovoljca/:volunteerId',
          name: 'CovidTestiranjeProstovoljca',
          component: () => import('@/components/Sopotniki/volunteers/CovidTestiranjeProstovoljca')
        },
        {
          path: '/volunteer/dovoljenje-za-vozilo/:volunteerId',
          name: 'DovoljenjeZaUporaboSluzbenegaVozila',
          component: () => import('@/components/Sopotniki/volunteers/DovoljenjeZaUporaboSluzbenegaVozila')
        },
        {
          path: '/volunteer/pristopna-izjava-za-zavarovanje/:volunteerId',
          name: 'PristopnaIzjavaZaZavarovanje',
          component: () => import('@/components/Sopotniki/volunteers/PristopnaIzjavaZaZavarovanje')
        },
        {
          path: '/volunteer/izjava-o-prejemu-opreme/:volunteerId',
          name: 'IzjavaOprejemuVrnitviOpreme',
          component: () => import('@/components/Sopotniki/volunteers/IzjavaOprejemuVrnitviOpreme')
        },
        {
          path: '/intermodal-transportation/potovalni-nacrt/:intermodalReservationId/:unitId',
          name: 'PdfTravelPlan',
          component: () => import('@/components/Sopotniki/reservations/IntermodalReservationTravelPlan')
        },
        {
          path: '/customer/id-card/:customerId',
          name: 'CustomerIdCard',
          component: () => import('@/components/Sopotniki/customers/IdCard')
        },
        {
          path: '/statistics/statisticsOnDonations',
          name: 'StatisticsOnDonations',
          component: () => import('@/components/Sopotniki/statistics/PorociloZaDonatorja')
        },
        {
          path: '/statistics/statisticsOnVolunteers/insurance',
          name: 'StatisticsOnVolunteersInsurance',
          component: () => import('@/components/Sopotniki/statistics/PorociloZaZavarovalnico')
        },
        {
          path: '/statistics/statisticsOnVolunteers/socialWorkCentre',
          name: 'StatisticsOnVolunteersSocialWorkCentre',
          component: () => import('@/components/Sopotniki/statistics/PorociloZaCSD')
        },
        {
          path: '/statistics/statisticsOnVolunteers/travelCosts',
          name: 'StatisticsOnVolunteersTravelCosts',
          component: () => import('@/components/Sopotniki/statistics/PorociloZaPotneStroske')
        },
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})

router.beforeEach((to, from, next) => {
  console.log("store", store);
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (Object.keys(store.state.AppActiveUser).length > 0 || JSON.parse(localStorage.getItem('userInfo'))) {
      next();
    } else {
      console.warn("Za dostop do strani morate biti prijavljeni!");
      next({name: 'Login'})
    }
  } else {
    next();
  }

});
export default router
