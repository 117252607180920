import store from '../store/store.js';

export default {
  sleep(delay) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  },

  isAdmin() {
   if(localStorage.getItem('userInfo')) {
      return JSON.parse(localStorage.getItem('userInfo')).role === 1 || JSON.parse(localStorage.getItem('userInfo')).role === 2 ? true : false;
   } else {
     console.error("Cannot determine if admin!");
     return false
    }
  },

  getUnitDataById(unitId) {
    const _this = this;

    if (localStorage.getItem('userInfo')) {
      let unit = _.filter(JSON.parse(localStorage.getItem('userInfo')).units, {id: unitId})[0];
      if (unit) {
        return unit;
      } else
        return 'podatka ni bilo mogoče pridobiti';
    }

  },

  getCurrentUser() {
    if(localStorage.getItem('userInfo')) {
      return JSON.parse(localStorage.getItem('userInfo'));
    } else {
      console.error("No user info present!");
      return false
    }
  },

  getCurrentUnit() {
    if(localStorage.getItem('selectedUnit')) {
      return JSON.parse(localStorage.getItem('selectedUnit'))
    } else if(localStorage.getItem('userInfo')) {
      return JSON.parse(localStorage.getItem('userInfo')).units[0]
    } else {
      console.error("Cannot select unit!")
    }
  },

  getUserAvatar(image) {
    return 'https://sopotniki.azurewebsites.net/volunteer/file/' + btoa(encodeURI('https://drive.google.com/thumbnail?authuser=0&sz=w' + 50 + '&id=' + image))
  },

  getImage(image) {
    return 'https://sopotniki.azurewebsites.net/volunteer/file/' + btoa(encodeURI('https://drive.google.com/thumbnail?authuser=0&sz=w' + 1000 + '&id=' + image))
  },

  formatSecondsToReadableDuration(seconds) {
      let minutes = Math.floor(seconds / 60);
      seconds = seconds % 60;
      let hours = Math.floor(minutes / 60)
      minutes = minutes % 60;
      return hours + "h " + ("0" + minutes).slice(-2) + "min ";
  },

  getCarData(carId) {
    if(localStorage.getItem('selectedUnit')) {
      return _.find(JSON.parse(localStorage.getItem('selectedUnit')).cars, {id: carId});
    } else if(localStorage.getItem('userInfo')) {
      return _.find(JSON.parse(localStorage.getItem('userInfo')).units[0].cars, {id: carId});
    } else {
      console.error("Cannot find a car!")
    }
  },

  parseGeocompleteData(result, searchItem) {

    // console.log("result -------> ", result);
    // console.log("searchItem -------> ", searchItem);

    let geocompleteData = "";

    if (typeof (_.find(result.address_components, function (ac) {
      return ac.types[0] == searchItem
    })) != 'undefined') {
      geocompleteData = (_.find(result.address_components, function (ac) {
        return ac.types[0] == searchItem
      }).long_name);
    }
    console.log(geocompleteData);
    return geocompleteData;
  },

  getCarByIdFromStore(carId) {
    let cars = store.getters.getAllCars;
    return _.find(cars, {id: parseInt(carId)});
  },

  getReservationPurposeIcon(reservationPurposeId) {
    let icon = null;

    switch(reservationPurposeId) {
      case 1:
        icon = 'medical_services';
        break;
      case 2:
        icon = 'shopping_cart';
        break;
      case 3:
        icon = 'supervised_user_circle';
        break;
      case 4:
        icon = 'directions_car';
        break;
      case 5:
        icon = 'event_seat';
        break;
      case 6:
        icon = 'fireplace';
        break;
      case 7:
        icon = 'hot_tub';
        break;
      case 8:
        icon = 'local_shipping';
        break;
      case 11:
        icon = 'science';
        break;
      case 12:
        icon = 'fastfood';
        break;
      case 13:
        icon = 'set_meal';
        break;
      case 14:
        icon = 'flaky';
        break;
      case 15:
        icon = 'shopping_basket';
        break;
      case 16:
        icon = 'local_taxi';
        break;
    }
    return icon;
  },

  getReservationPurposeLabel(reservationPurposeId) {
    let label = null;

    switch(parseInt(reservationPurposeId)) {
      case 1:
        label = 'Zdravnik';
        break;
      case 2:
        label = 'Storitve (trgovina, banka, pošta, frizer ipd.)';
        break;
      case 3:
        label = 'Obisk';
        break;
      case 4:
        label = 'Izlet';
        break;
      case 5:
        label = 'Dogodek (kulturni, zabavni ipd.)';
        break;
      case 6:
        label = 'Pokopališče';
        break;
      case 7:
        label = 'Zdravilišče';
        break;
      case 8:
        label = 'COVID-19 - dostava zdravil';
        break;
      case 11:
        label = 'COVID-19 - dostava brisov';
        break;
      case 12:
        label = 'COVID-19 - dostava toplih obrokov otrokom';
        break;
      case 13:
        label = 'COVID-19 - dostava paketov hrane otrokom';
        break;
      case 14:
        label = 'COVID-19 - hitri testi';
        break;
      case 15:
        label = 'COVID-19 - dostava živil';
        break;
      case 16:
        label = 'COVID-19 - prevoz na cepljenje';
        break;
    }
    return label;
  },

  getFileTypeIcon(mimeType) {
    let iconClass = "";

    // console.log("getFileTypeIcon for mimeType: ", mimeType);
    if (mimeType === null || mimeType === '') {
      return ''
    }

    switch (this.getFileTypeFromMimeType(mimeType)) {
      case 'pdf':
        iconClass = "fa fa-file-pdf";
        break;
      case 'word':
        iconClass = "fa fa-file-word";
        break;

      case 'excel':
        iconClass = "fa fa-file-excel";
        break;

      case 'powerpoint':
        iconClass = "fa fa-file-powerpoint";
        break;

      case 'text/csv':
        iconClass = "fa fa-file-csv";
        break;

      case 'image':
        iconClass = "fas fa-file-image";
        break;

      case 'video':
        iconClass = "fas fa-file-video";
        break;

      case 'audio':
        iconClass = "fas fa-file-audio";
        break;

      case 'text':
        iconClass = "fas fa-file-alt";
        break;

      case 'archive':
        iconClass = "far fa-file-archive";
        break;

      case 'unknown':
        iconClass = "fas fa-file-alt";
        break;

      default:
        iconClass = "fas fa-file-alt";
        break;
    }
    return iconClass
  },

  getFileTypeFromMimeType(mimeType) {
    let fileType = "";

    // console.log("fileType from mimeType: ", mimeType);
    if (mimeType === null || mimeType === '') {
      return ''
    }

    switch (mimeType) {
      case 'application/pdf':
        fileType = "pdf";
        break;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
        fileType = "word";
        break;

      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
        fileType = "excel";
        break;

      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.openxmlformats-officedocument.presentationml.template':
      case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
        fileType = "powerpoint";
        break;

      case 'text/csv':
        fileType = "csv";
        break;

      case 'image/bmp':
      case 'image/cis-cod':
      case 'image/gif':
      case 'image/ief':
      case 'image/jpeg':
      case 'image/png':
      case 'image/pipeg':
      case 'image/svg+xml':
      case 'image/tiff':
      case 'image/x-cmu-raster':
      case 'image/x-cmx':
      case 'image/x-icon':
      case 'image/x-portable-anymap':
      case 'image/x-portable-bitmap':
      case 'image/x-portable-graymap':
      case 'image	image/x-portable-pixmap':
      case 'image/x-rgb':
      case 'image/x-xbitmap':
      case 'image/x-xwindowdump':
        fileType = "image";
        break;

      case 'video/x-flv':
      case 'video/mp4':
      case 'application/x-mpegURL':
      case 'video/MP2T':
      case 'video/3gpp':
      case 'video/quicktime':
      case 'video/x-msvideo':
      case 'video/x-ms-wmv':
        fileType = "video";break;

      case 'audio/basic':
      case 'audio/L24':
      case 'audio/mid':
      case 'audio/mpeg':
      case 'audio	audio/mp4':
      case 'audio/x-aiff':
      case 'audio/x-mpegurl':
      case 'audio/vnd.rn-realaudio':
      case 'audio/ogg':
      case 'audio/vorbis':
      case 'audio/vnd.wav':
        fileType = "audio";
        break;

      case 'application/vnd.oasis.opendocument.text':
      case 'application/vnd.oasis.opendocument.text-template':
      case 'application/vnd.oasis.opendocument.text-web':
      case 'application/vnd.oasis.opendocument.text-master':
        fileType = "text";
        break;

      case 'application/zip':
      case 'application/rar':
      case 'application/tar':
      case 'application/arj':
        fileType = "archive";
        break;

      default:
        fileType = "unkonwn";
        break
    }
    return fileType
  },

  roundNumber(value, precision) {
    let multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}

