/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Sopotniki Admin 2.0.
  Author: Zavod Sopotniki (Markus Zevnik)
  Author URL: https://www.sopotniki.org
==========================================================================================*/
import Vue from "vue";

const actions = {

  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth({commit}, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
  },

  // VxAutoSuggest
  updateStarredPage({commit}, payload) {
    commit('UPDATE_STARRED_PAGE', payload)
  },

  // The Navbar
  arrangeStarredPagesLimited({commit}, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list)
  },
  arrangeStarredPagesMore({commit}, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list)
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay({commit}) {
    commit('TOGGLE_CONTENT_OVERLAY')
  },
  updateTheme({commit}, val) {
    commit('UPDATE_THEME', val)
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  updateUserInfo({commit}, payload) {
    commit('UPDATE_USER_INFO', payload)
  },

  setSelectedDateForReservations({commit}, payload) {
    commit('SET_SELECTED_DATE_FOR_RESERVATIONS', payload)
  },

  setSelectedDateForIntermodalReservations({commit}, payload) {
    commit('SET_SELECTED_DATE_FOR_INTERMODAL_RESERVATIONS', payload)
  },

  setSelectedDateForCanceledReservations({commit}, payload) {
    commit('SET_SELECTED_DATE_FOR_CANCELED_RESERVATIONS', payload)
  },

  setSelectedDateForDeclinedReservations({commit}, payload) {
    commit('SET_SELECTED_DATE_FOR_DECLINED_RESERVATIONS', payload)
  },

  setSelectedDateForMonthlyReports({commit}, payload) {
    commit('SET_SELECTED_DATE_FOR_MONTHLY_REPORTS', payload)
  },

  setSelectedDateForFinancialReports({commit}, payload) {
    commit('SET_SELECTED_DATE_FOR_FINANCIAL_REPORTS', payload)
  },

  setSelectedDateForPrivateDonations({commit}, payload) {
    commit('SET_SELECTED_DATE_FOR_PRIVAT_DONATIONS', payload)
  },

  setSelectedDateForTravelOrders({commit}, payload) {
    commit('SET_SELECTED_DATE_FOR_TRAVEL_ORDERS', payload)
  },

  setActiveNode({commit}, data) {
    commit("SET_ACTIVE_NODE", data);
  },

  setActiveNodeContent({commit}, data) {
    commit("SET_ACTIVE_NODE_CONTENT", data);
  },

  async setAllCars({commit}) {

    await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'cars')
      .then((res) => {
        commit("SET_ALL_CARS", res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });

  },

  async getCoupons({commit}) {
    await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'coupons')
      .then((res) => {
        commit("SET_COUPONS", res.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  },


}

export default actions
