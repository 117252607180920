/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Sopotniki Admin 2.0.
  Author: Zavod Sopotniki (Markus Zevnik)
  Author URL: https://www.sopotniki.org
==========================================================================================*/

// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

const getters = {

	// COMPONENT
		// vx-autosuggest
	// starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: state => {

    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return "xl"
    else if (state.windowWidth >= 992) return "lg"
    else if (state.windowWidth >= 768) return "md"
    else if (state.windowWidth >= 576) return "sm"
    else return "xs"
  },

  scrollbarTag: state => {
    return state.is_touch_device ? 'div' : 'VuePerfectScrollbar'
  },

  getSelectedDateForReservations: state => state.selectedDateForReservations,
  getSelectedDateForIntermodalReservations: state => state.selectedDateForIntermodalReservations,
  getSelectedDateForCanceledReservations: state => state.selectedDateForCanceledReservations,
  getSelectedDateForDeclinedReservations: state => state.selectedDateForDeclinedReservations,
  getSelectedDateForMonthlyReports: state => state.selectedDateForMonthlyReports,
  getSelectedDateForFinancialReports: state => state.selectedDateForFinancialReports,
  getSelectedDateForPrivateDonations: state => state.selectedDateForPrivateDonations,
  getSelectedDateForTravelOrders: state => state.selectedDateForTravelOrders,
  getActiveNode: state => state.activeNode,
  getActiveNodeContent: state => state.activeNodeContent,
  getAllCars: state => state.allCars,
  getCoupons: state => state.coupons,
}

export default getters
